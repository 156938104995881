/**
 * The JQuery Carousel
 *
 * @type {Object}
 */
var $carousel = null;
/**
 * The current prayer
 *
 * @type {Null|Object}
 */
var currentPrayer = null;
/**
 * The date picker
 *
 * @type {Null|Object}
 */
var $datePicker = null;
/**
 * The total number of seconds the Year of Prayer API throttles requests that
 * state you are praying
 * @type {Number}
 */
var apiThrottle = 3600;
/**
 * The number of seconds to poll the server
 *
 * @type {Number}
 */
var pollServerTime = 120;
/**
 * The polling timeout
 *
 * @type {Function}
 */
var pollingTimeout = null;
/**
 * A callback triggered everytime the language change
 *
 * @type {Function}
 */
var languageChangeCallback = function() {};
/**
 * Load the language service
 *
 * @type {SectionService}
 */
var languageService = new LanguageService();
/**
 * Load the PrayerApi service
 *
 * @type {PrayerApiService}
 */
var prayerApiService = new PrayerApiService();
/**
 * Load the model prayer service
 *
 * @type {PrayerService}
 */
var prayerService = new PrayerService(languageService);
/**
 * The current prayers stats
 *
 * @type {Object}
 */
var prayerStats = {};
/**
 * Load the model section service
 *
 * @type {SectionService}
 */
var sectionService = new SectionService(languageService);
/**
 * The URL for sharing.  {0} - The Domain, {1} - The Page Id
 *
 * @type {String}
 */
var shareUrl = '{0}/prayers/{1}/{2}';
/**
 * The JQuery Social Sharing plugin
 *
 * @type {JSSocials}
 */
var $socialShare = null;
/**
 * A list of supported languages pulled from the language nav
 *
 * @type {Array}
 */
var supportedLanguages = [];

jQuery(document).ready(function($) {

  $('#name-input, #email-input, #message-input').focus(function(event) {
    $(this).val('');
  });
  $('#contact-form').submit(function(event) {
    var errorMessage = languageService.translate('WEB_INTERFACE.FORM.ERROR_MESSAGE');
    var $form = $(this);
    $form.find('button').eq(0).attr('disabled', true);
    if (validContactForm()) {
      $form.find('.form-group').removeClass('has-error');
      submitContactForm($form);
    } else {
      $('#contact-response').fadeOut('slow', function() {
        $form.find('button').eq(0).attr('disabled', false);
        $(this).html('<p class="text-danger">' + errorMessage + '</p>').fadeIn('slow');
      });
    }
    event.preventDefault();
    return false;
  });
  if ($('.home-page').length > 0) {
    setupHome();
  }

  setupNav();

  $socialShare = $('#prayer-share').jsSocials({
    showLabel: false,
    showCount: false,
    shares: ['email', 'twitter', 'facebook', 'googleplus', 'linkedin', 'whatsapp']
  });

  $('[data-toggle="tooltip"]').tooltip();
  $('.open-jordan-panel').click(function(event) {
    event.stopPropagation();
    $('.collapse').removeClass('in');
    $('#panel-4.collapse').collapse('show');
    $('html, body').animate({
      scrollTop: $('#learn-more').offset().top-5
    }, 300, function(){});
    return false;
  });
});
/**
 * Create the learn more accordion
 * @param  {String} title             The title for the section
 * @param  {Array} content            An array of Page models to display in the accordion
 * @param  {String} language          The three letter language code for the section
 * @param  {String} languageDirection The direction of the language for the section
 * @return {Void}
 *
 * @access public
 */
function createAccordion(title, language, languageDirection, content) {
  $('#learn-more')
    .attr({
      'lang': language.htmlTag,
      'dir':  language.direction
    })
    .text(title);
  var $accordion = $('#learn-more-accordion');
  $accordion.html('');
  $.each(content, function(key, page) {
    var $panel = $('#panel-template').clone();
    var panelNumber = key+1;
    $panel
      .attr('id', page.slug)
      .removeClass('hidden');
      $panel
        .find('.panel-heading')
        .first()
        .attr({
          'lang': page.language.htmlTag,
          'dir':  page.language.direction
        });
    $panel
      .find('.panel-heading > a')
      .first()
      .attr('href', '#panel-'+panelNumber)
      .text(page.title);
    $panel.find('.panel-collapse')
      .first()
      .attr('id', 'panel-'+panelNumber);
    $panel.find('.panel-body')
      .first()
      .attr({
        'lang': page.language.htmlTag,
        'dir':  page.language.direction
      })
      .html(page.content);
    if (panelNumber === 1) {
      $panel.find('.panel-collapse')
        .first()
        .addClass('in');
    }
    $accordion.append($panel);
  });
};
/**
 * Create the date picker
 *
 * @return {Void}
 * @access public
 */
function createPicker() {
  var language = languageService.getLanguage();
  if (languageService.useFallbackFile) {
    var language = languageService.getFallbackLanguage();
  }
  var months = languageService.translate('WEB_INTERFACE.CALENDAR.MONTHS');
  var days = languageService.translate('WEB_INTERFACE.CALENDAR.DAYS_OF_WEEK');
  var today = languageService.translate('WEB_INTERFACE.BUTTONS.CALENDAR.TODAY');
  var close = languageService.translate('WEB_INTERFACE.BUTTONS.CALENDAR.CLOSE');
  var clear = languageService.translate('WEB_INTERFACE.BUTTONS.CALENDAR.CLEAR');
  if (($datePicker) && ($datePicker.get('start'))) {
    $datePicker.stop();
    $datePicker = null;
  }
  var pickerElement = $('#date-selector').pickadate({
    monthsFull: months,
    weekdaysShort: days,
    format: 'mm-dd',
    today: today,
    clear: clear,
    close: close,
    onSet: function() {
      var newId = $datePicker.get();
      if (newId) {
        setPathHash(newId);
        setPrayer();
        setPrayerStats();
      }
    }
  });
  $datePicker = pickerElement.pickadate('picker');

  pickADateFix();

  if (language.direction === 'rtl') {
    $('.picker,.picker__nav--next,.picker__nav--prev').addClass('rtl');
  } else {
    $('.picker,.picker__nav--next,.picker__nav--prev').removeClass('rtl');
  }
};
/**
 * Checks if there is a prayerId in the URL, if so, it will retrieve it
 *
 * @return {String|null} Checks whether there is a prayer id in the url and returns it
 *
 * @access public
 */
function getPrayerId() {
  var id = null;
  var hash = url('hash');
  if (hash) {
    var match = hash.match(/!prayers\/{1}([0-9\-]*)\/?/);
    if ((match) && (prayerService.isValidId(match[1]))) {
      id = match[1];
    }
  } else {
    /**
     * Check the URL
     */
    var currentUrl = window.location.href;
    var match = currentUrl.match(/prayers\/{1}([0-9\-]*)\/?/);
    if ((match) && (prayerService.isValidId(match[1]))) {
      id = match[1];
    }
  }
  return id;
};
/**
 * Get the stats for the prayer
 *
 * @return {void}
 * @access public
 */
function getPrayerStats() {
  var prayerId = getPrayerId();
  var apiKey = Cookies.get('yop-api-key');

  if (!prayerId) {
    prayerId = prayerService.todaysId();
  }

  return prayerApiService.prayerStats(apiKey, prayerId);
};
/**
 * Check if the user has been throttled on the API
 *
 * @param  {String}  lastRequest The last time they submitted a prayer request (timestamp)
 * @return {Boolean}             Are they throttled?
 */
function isThrottled(lastRequest) {
  var currentTime = Math.floor(Date.now() / 1000);
  return ((lastRequest) && ((currentTime - lastRequest) < apiThrottle));
}
/**
 * When the praying button is clicked
 *
 * @access public
 */
function joinPrayer() {
  var prayerId = getPrayerId();
  var apiKey = Cookies.get('yop-api-key');

  if (!prayerId) {
    prayerId = prayerService.todaysId();
  }

  $('.praying-button').attr('disabled', true);

  registerConsumer().then(function(apiKey) {
    prayerApiService.praying(apiKey, prayerId).then(function(success) {
      if (success) {
        prayerStats.total_prayers = prayerStats.total_prayers+1;
        $('.prayer-count').text(prayerStats.total_prayers);
      }

    });
  }, function(error) {
    console.error(error.responseText);
  });

};
/**
 * Format a number with commas
 *
 * @param  {Number} x The number to format
 * @return {String}   The formatted string
 * @access private
 */
function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
/**
 * If the Consumer does not have an API key, then register them
 *
 * @return {Promise}  A promise to return an apiKey
 *
 * @access public
 */
function registerConsumer() {
  var deferred = $.Deferred();
  var apiKey = Cookies.get('yop-api-key');
  if (!apiKey) {
    prayerApiService.register().then(
      function(apiKey) {
        if (apiKey) {
          Cookies.set('yop-api-key', apiKey);
          deferred.resolve(apiKey);
        }
      },
      function(error) {
        deferred.reject(error);
      }
    );
  } else {
    deferred.resolve(apiKey);
  }
  return deferred.promise();
};
/**
 * Take all data-translate elements and translate them
 * @access public
 */
function setInterfaceTranslation() {
  var language = languageService.getLanguage();
  if (languageService.useFallbackFile) {
    var language = languageService.getFallbackLanguage();
  }
  $.each($('[data-translate]'), function(index, el) {
    var $element = $(el);
    var allowHtml = $element.data('translate-html');
    var location = $element.data('translate');
    if (location !== '') {
      var translation = languageService.translate(location);
      if ($element.is('input') || $element.is('textarea')) {
        $element.attr('placeholder', translation);
      }
      if (allowHtml) {
        $element.html(translation);
      } else {
        $element.text(translation);
      }
      $element.attr({
        'lang': language.htmlTag,
        'dir':  language.direction
      });
    }
  });
  /**
   * Elements that need the direction attribute
   */
  $.each($('[data-translate-direction]'), function(index, el) {
    $(el).attr({
      'lang': language.htmlTag,
      'dir':  language.direction
    });
  });
};
/**
 * Set the learn more section
 *
 * @access public
 */
function setLearnMore() {
  sectionService.find('learn_more').then(function(section) {
    var content = [];
    $.each(section.pages, function(index, page) {
      if (page.page_type === 'page') {
        content.push(page);
      }
    });
    if (content.length === 0) {
      $('.learn-more-page-header').hide();
    } else {
      createAccordion(section.title, section.language, section.language_direction, content);
    }
  }).fail(function() {
    $('.learn-more-page-header').hide();
  });
};
/**
 * Setup the Home page
 *
 * @return {Void}
 * @access public
 */
function setupHome() {
  $('.trigger-date-picker').click(function(e) {
    if (($datePicker) && (!$datePicker.get('open'))) {
      $datePicker.open(false);
    }
    e.stopPropagation();
    return false;
  });
  $('.praying-button').click(function(e) {
    joinPrayer();
    e.preventDefault();
    return false;
  });
  languageChangeCallback = function() {
    createPicker();
    setPrayer();
    setLearnMore();
    setInterfaceTranslation();
    setPrayerStats();
  };
};
/**
 * Setup the navigation
 *
 * @return {Void}
 * @access public
 */
function setupNav() {
  var $languageNav = $('#language-menu');

  $("ul#main-nav li a[href^='#']").on('click', function(e) {
    e.preventDefault();
    var hash = this.hash;
    $('html, body').animate({
      scrollTop: $(hash).offset().top-5
    }, 300, function(){});
  });

  $("#language-menu li a[href^='#']").on('click', function(e) {
    e.preventDefault();
    $li = $(this).parent('li');
    var defaultLanguage = ($li.data('default') === 1) ? true : false;
    var lang = new Language(
      {
        language:       $li.data('lang'),
        default:        defaultLanguage,
        direction:      $li.data('direction'),
        string:         $.trim($li.text()),
        region:         $li.data('region')
      }
    );
    setPreferredLanguage(lang);
  });

  $.each($languageNav.children('li'), function(index, child) {
    var $li = $(child);
    var defaultLanguage = ($li.data('default') === 1) ? true : false;
    supportedLanguages.push(new Language(
      {
        language:   $li.data('lang'),
        default:    defaultLanguage,
        direction:  $li.data('direction'),
        string:     $.trim($li.text()),
        region:     $li.data('region')
      }
    ));
  });

  var preferredLanguage = Cookies.getJSON('preferred-language');

  if (typeof preferredLanguage !== 'undefined') {
    setPreferredLanguage(preferredLanguage);
  } else {
    setPreferredLanguage();
  }
};
/**
 * Set the hash for the page
 *
 * @param {String} newPrayerId The new prayer.id
 * @access public
 */
function setPathHash(newPrayerId) {
  var prayerId = getPrayerId();
  var hash = url('hash');
  if (hash) {
    location.hash = location.hash.replace('!prayers/'+prayerId, '!prayers/'+newPrayerId);
  } else {
    /**
     * If there is a prayerId, but no Hash, we are on a prayer page.  Redirect to the home page
     *
     */
    if (prayerId) {
      window.location.href = '/#!prayers/'+newPrayerId;
    } else {
      location.hash = '!prayers/'+newPrayerId;
    }
  }
};
/**
 * Set the preferred language
 *
 * @param  {Language} language   The preferred language (optional)
 * If not set, it will set to the default language
 *
 * @access public
 */
function setPreferredLanguage(language) {
  var defaultLanguage;
  var $languageNavTitle = $('#language-nav-title');
  $.each(supportedLanguages, function(index, lang) {
    if (lang.default) {
      defaultLanguage = lang;
    }
  });
  var preferred = (typeof language !== 'undefined') ? language : defaultLanguage;
  $languageNavTitle
    .attr({
      'lang':   preferred.htmlTag,
      'dir':    preferred.direction
    })
    .text(preferred.string);

  if (preferred.direction === 'rtl') {
    $languageNavTitle.addClass('text-right');
  } else {
    $languageNavTitle.removeClass('text-right');
  }
  languageService.setLanguage(preferred);
  languageService.setFallbackLanguage(defaultLanguage);
  Cookies.set('preferred-language', preferred);
  languageService.loadTranslations().then(function() {
    languageChangeCallback();
  });
};
/**
 * Set the prayer stats for the view
 *
 * @access public
 */
function setPrayerStats() {
  if (pollingTimeout) {
    clearTimeout(pollingTimeout);
  }
  $('.praying-button').attr('disabled', true);

  registerConsumer().then(function(apiKey) {
    getPrayerStats().then(function(stats) {
      prayerStats = stats;
      if (!isThrottled(prayerStats.your_last_prayer_on)) {
        $('.praying-button').attr('disabled', false);
      }
      $('.prayer-count').text(prayerStats.total_prayers);
      /**
       * Setup the polling
       */
      pollingTimeout = setTimeout(setPrayerStats, pollServerTime*1000);
    });
  }, function(error) {
    console.error(error.responseText);
  });
};
/**
 * Set the prayer for the day
 *
 * @access public
 */
function setPrayer() {
  var months = languageService.translate('WEB_INTERFACE.CALENDAR.MONTHS');
  var prayerId = getPrayerId();
  var prayerPromise = null;
  if (prayerId) {
    if (prayerService.isValidId(prayerId)) {
      prayerPromise = prayerService.findById(prayerId);
    }
  } else {
    prayerPromise = prayerService.today();
  }
  if (prayerPromise) {
    prayerPromise.then(function(prayer) {
      if (prayer) {
        currentPrayer = prayer;
        var dayString = months[currentPrayer.month - 1]+ ' ' + currentPrayer.day;
        setPrayerContent(
          currentPrayer.id,
          currentPrayer.title,
          dayString,
          currentPrayer.details,
          currentPrayer.images,
          currentPrayer.language.htmlTag,
          currentPrayer.language.direction
        );
      }
    }).fail(function() {
      setPrayerContent('', 'Prayer Missing', '', 'Sorry, unable to find the prayer.', [], 'eng', 'ltr');
    });
  } else {
    setPrayerContent('', 'Prayer Missing', '', 'Sorry, unable to find the prayer.', [], 'eng', 'ltr');
  }
};
/**
 * Set the Prayer content
 * @param  {String} id                Prayer ID
 * @param  {String} title             Prayer Title
 * @param  {String} dateString        Prayer Date String
 * @param  {String} details           Prayer Details
 * @param  {Array} images             The Prayer Images
 * @param  {String} language          The three letter language code
 * @param  {String} languageDirection The direction of the language
 *
 * @access public
 */
function setPrayerContent(id, title, dateString, details, images, language, languageDirection) {
  var $slideshow = $('div.owl-carousel');
  var prayerId = getPrayerId();
  var appName = languageService.translate('WEB_INTERFACE.APP_NAME');
  var pageTitle = appName + ': ' +dateString;
  if (title) {
    pageTitle += ' - ' + title;
  }
  var domain = document.location.protocol +"//"+ document.location.hostname;
  var pageUrl = shareUrl.format(domain, id, language);
  /**
   * Replace all quotes
   */
  var pageDescription = details
    .truncate(150, true)
    .replace(/['"]+/g, '')
    .replace(/[\u2018\u2019]/g, '')
    .replace(/[\u201C\u201D]/g, '');

  $slideshow.html('');
  $('#daily-prayer')
    .attr({
      'lang': language,
      'dir': languageDirection
    })
    .text(title);
  $('#prayer-date')
    .attr({
      'lang': language,
      'dir': languageDirection
    })
    .text(dateString);
  $('#prayer-details')
    .attr({
      'lang': language,
      'dir': languageDirection
    })
    .html(details);
  if (images.length > 0) {
    $.each(images, function(index, image) {
      var imageElement = $('<div/>').addClass('item').append($('<img />').attr({src: image, alt: 'Prayer Image'}));
      $slideshow.append(imageElement);
    });
    startCarousel();
  }
  $socialShare.jsSocials('option', 'text', pageTitle);
  $socialShare.jsSocials('option', 'url', pageUrl);
  if (prayerId) {
    document.title = pageTitle;
    $('meta[name=description]').remove();
    $('head').append('<meta name="description" content="' + pageDescription + '">');
  }
};
/**
 * Start the owl Carousel.
 * @return {Void}
 * @access public
 */
function startCarousel() {
  if ($carousel) {
    $carousel.trigger('destroy.owl.carousel');
    $carousel = null;
  }
  $carousel = $('.owl-carousel').owlCarousel({
    loop:true,
    center: true,
    margin:10,
    dots: true,
    autoplay: true,
    autoplayTimeout: 6000,
    responsive:{
        0:{
            items:1
        },
        600:{
            items:1
        },
        1000:{
            items:1
        }
    }
  });
};
/**
 * Submit the data on the contact form.
 *
 * @param   $form   The form to submit
 * @return  {Void}
 * @access  public
 */
function submitContactForm($form) {
  var successMessage = languageService.translate('WEB_INTERFACE.FORM.SUCCESS_MESSAGE');
  var errorMessage = languageService.translate('WEB_INTERFACE.FORM.ERROR_MESSAGE');
  $.ajax({
    url: $form.attr('action'),
    type: 'POST',
    dataType: 'json',
    data: $form.serialize()
  })
  .done(function(response) {
    if (response.success !== '') {
      $('#contact-response').fadeOut('slow', function() {
        $form.find('button').eq(0).attr('disabled', false);
        $('#name-input, #email-input, #message-input').val('');
        $(this).html('<p>' + successMessage + '</p>').fadeIn('slow');
      });
    }
  })
  .fail(function(errors) {
    var data = errors.responseJSON;
    if (data.hasOwnProperty('errors')) {
      $.each(data.errors, function(index, error) {
        $('[name="' + error.key+ '"]').first().parent('.form-group').addClass('has-error');
      });
      $('#contact-response').fadeOut('slow', function() {
        $form.find('button').eq(0).attr('disabled', false);
        $(this).text('<p class="text-danger">' + errorMessage + '</p>').fadeIn('slow');
      });
    }
  });

};
/**
 * Checks if the contact form is valid.  If not, it will highlight invalid inputs.
 *
 * @return {Boolean} Is it valid?
 * @access public
 */
function validContactForm() {
  var valid = true;
  var name = $('#name-input');
  var email = $('#email-input');
  var message = $('#message-input');
  var phone = $('#phone-input');
  if (name.val() === '') {
    name.parent('.form-group').addClass('has-error');
    valid = false;
  } else {
    name.parent('.form-group').removeClass('has-error')
  }
  if ((email.val() === '') || (!validEmail(email.val()))) {
    email.parent('.form-group').addClass('has-error');
    valid = false;
  } else {
    email.parent('.form-group').removeClass('has-error')
  }
  if (message.val() === '') {
    message.parent('.form-group').addClass('has-error');
    valid = false;
  } else {
    message.parent('.form-group').removeClass('has-error')
  }
  if (phone.val() !== '') {
    /**
     * Spam Trap
     */
    valid = false;
  }
  return valid;
};
/**
 * Is it a valid email?
 * @link http://stackoverflow.com/a/46181
 *
 * @param  {String} email The email address to test
 * @return {Boolean}      Is it valid?
 * @access private
 */
function validEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}
/**
 * Fixes an issue with the date picker shown at this issue:
 * https://github.com/amsul/pickadate.js/issues/770
 *
 * @return {Void}
 * @access public
 */
function pickADateFix() {
  var prevActiveElement;
  var original = $('#date-selector').get(0);
  $(window).on('blur', function(e) {
    prevActiveElement = document.activeElement.classList.contains('picker__holder') ? original : null;
  }).on('focus', function(e) {
    var theOrg = document.activeElement.classList.contains('picker__holder') ? original : null;
    if (theOrg == prevActiveElement && $datePicker.get('open') == false) {
      // force close ASAP
      requestAnimationFrame(function() {
        $datePicker.close();
      });
    }
  });

  $('input').on('focus', function(e) {
    prevActiveElement = document.activeElement.classList.contains('picker__holder') ? original : null;
  });

  $datePicker.on('close',function() {
    prevActiveElement = null;
  });
};
